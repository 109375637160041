/*
  SharedModule declares :
  - components,
  - directives, (via UtilsModule)
  - pipes (via UtilsModule)
  that are used everywhere in the app.
  SharedModule exports Widget Modules:
  - CommonModule
  - FormsModule & ReactiveFormsModule
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { KzCheckBoxModule } from '../kz-ui/ui/form/checkbox/kz-checkbox.module';
import { KzFieldModule } from '../kz-ui/ui/form/field/kz-field.module';
import { KzLabelModule } from '../kz-ui/ui/form/label/kz-label.module';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { UtilsModule } from '../utils/utils.module';
import {
  KazabookMediaButtonComponent
} from './components/action-buttons/kazabook-media-button/kazabook-media-button.component';
import {
  ContestCompetitorBadgeComponent
} from './components/contest-competitor-badge/contest-competitor-badge.component';
import { ContestWinnerBadgeComponent } from './components/contest-winner-badge/contest-winner-badge.component';
// import { FavoritePedagoPopinComponent } from './components/favorite-pedago-popin/favorite-pedago-popin.component';
// import { FavoriteButtonComponent } from './components/action-buttons/favorite-button/favorite-button.component';
import { OnDestroyComponent } from './components/on-destroy/on-destroy.component';
import { DropOutsideDirective } from '../utils/directives/drop-outside.directive';
import { KzTextboxDirective } from '../utils/directives/kz-textbox.directive';
import { OnBgImgLoadDirective } from '../utils/directives/on-bg-img-load.directive';
import { ToArrayPipe } from '../utils/pipes/to-array.pipe';
import { ToHashtagPipe, ToHashtagPipeB } from '../utils/pipes/to-hashtag.pipe';
import { IndexOfPipe } from '../utils/pipes/index-of.pipe';
import { ImgWeightOrderPipe } from '../utils/pipes/img-weight-order.pipe';
import { HashtagHighlightPipe } from '../utils/pipes/hashtag-highlight.pipe';
import { FileSizePipe } from '../utils/pipes/file-size.pipe';
import { MoodFilter } from '../utils/pipes/mood-filter.pipe';
import { LinkHighlightPipe } from '../utils/pipes/link-highlight.pipe';
import { ArrowKeyPressDirective } from '../utils/directives/arrow-key-press.directive';
import { OrderByDatePipe } from '../utils/pipes/order-by-date.pipe';
import { AutocompleteDirective } from '../utils/directives/autocomplete/autocomplete.directive';
import { PingsPipe } from '../utils/pipes/pings.pipe';
import { PostService } from './services/post';
import { RegisterSinceComponent } from './components/register-since/register-since.component';
import {
  KazaSimpleMiniCoverComponent
} from './components/kaza-mini-cover/kaza-simple-mini-cover/kaza-simple-mini-cover.component';
import { UpdatedSinceComponent } from './components/updated-since/updated-since.component';
import { AmbassadorBadgeComponent } from './components/ambassador-badge/ambassador-badge.component';
import { BreadcrumbModule } from './components/breadcrumb/breadcrumb.module';
import { KazaMiniCoverComponent } from './components/kaza-mini-cover/kaza-mini-cover/kaza-mini-cover.component';
import { AdminInspiButtonComponent } from './components/action-buttons/admin-inspi-button/admin-inspi-button.component';
import { KzTooltipModule } from '../kz-ui/ui/tooltip/kz-tooltip.module';
import { KzButtonModule } from '../kz-ui/ui/form/button/kz-button.module';
import { BlockCopyPasteModule } from './components/block-copy-paste/block-copy-paste.module';
import {
  DifferenceInDaysPipeModule,
  DifferenceInHoursPipeModule,
  DifferenceInMonthsPipeModule,
  DifferenceInSecondsPipeModule,
  DifferenceInYearsPipeModule,
  FormatDistanceToNowStrictPipeModule,
  FormatPipeModule,
  FormatRelativeToNowPipeModule,
} from 'ngx-date-fns';
import { KzIconsModule } from '../kz-ui/ui/icon/kz-icons.module';
import { KzBadgeModule } from '../kz-ui/ui/badge/kz-badge.module';
import { KzBravoModule } from '../kz-ui/ui/bravo/kz-bravo.module';
import { KazaFollowComponent } from '../modules/kaza/kaza-follow/kaza-follow.component';
import { KzLocalizePipe } from "../utils/pipes/i18n/kz-localize.pipe";
import { UserBadgeDirective } from '../utils/directives/user-badge.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    ReactiveFormsModule,
    RouterModule,
    DifferenceInYearsPipeModule,
    DifferenceInMonthsPipeModule,
    DifferenceInDaysPipeModule,
    DifferenceInHoursPipeModule,
    DifferenceInSecondsPipeModule,
    FormatRelativeToNowPipeModule,
    FormatDistanceToNowStrictPipeModule,
    FormatPipeModule,
    TranslateModule,
    LocalizeRouterModule,
    KzFieldModule,
    KzCheckBoxModule,
    KzLabelModule,
    KzTooltipModule,
    KzButtonModule,
    BlockCopyPasteModule,
    KzBadgeModule,
    KzIconsModule,
    KzBravoModule,
    KazaFollowComponent,
    KzLocalizePipe,
    UserBadgeDirective
  ],
  declarations: [
    RegisterSinceComponent,
    UpdatedSinceComponent,
    KazaMiniCoverComponent,
    // KazaFollowComponent,
    KazaSimpleMiniCoverComponent,
    OnDestroyComponent,
    AmbassadorBadgeComponent,
    ContestCompetitorBadgeComponent,
    ContestWinnerBadgeComponent,
    AdminInspiButtonComponent,
    KazabookMediaButtonComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    KazaMiniCoverComponent,
    // KazaFollowComponent,
    RegisterSinceComponent,
    UpdatedSinceComponent,
    KazaSimpleMiniCoverComponent,
    DropOutsideDirective,
    KzTextboxDirective,
    OnBgImgLoadDirective,
    ToArrayPipe,
    ToHashtagPipe,
    ToHashtagPipeB,
    IndexOfPipe,
    ImgWeightOrderPipe,
    HashtagHighlightPipe,
    FileSizePipe,
    MoodFilter,
    LinkHighlightPipe,
    ArrowKeyPressDirective,
    OrderByDatePipe,
    AutocompleteDirective,
    PingsPipe,
    DifferenceInYearsPipeModule,
    DifferenceInMonthsPipeModule,
    DifferenceInDaysPipeModule,
    DifferenceInHoursPipeModule,
    DifferenceInSecondsPipeModule,
    FormatRelativeToNowPipeModule,
    FormatDistanceToNowStrictPipeModule,
    AmbassadorBadgeComponent,
    ContestCompetitorBadgeComponent,
    ContestWinnerBadgeComponent,
    AdminInspiButtonComponent,
    KazabookMediaButtonComponent,
  ],
  providers: [
    PostService
  ]
})
export class SharedModule {
}
