import { HydraBase, HydraCollection, HydraMember } from "@adeo/ngx-kozikaza-api";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { CookieService } from "ngx-cookie";
import { Observable } from "rxjs";
export interface Shortcut extends HydraBase ,HydraMember {
  label: string;
  position: number;
}
@Injectable({
    providedIn: 'root'
  })
  export class SharedService {
    /* to be switched to the sdk*/
    private _locale: Locale;
    apiUrl = environment.API_BACKEND_ORIGIN
   
    constructor(private http:HttpClient,private cookiesService:CookieService){

    }
    getShortcuts() {
       return  this.http.get(this.apiUrl+'/discover_shortcuts',{ headers: this.getHeaders() });
    }

    public getHeaders(): HttpHeaders {
        const bearerToken = this.cookiesService.get(environment.ACCES_TOKEN);
         return new HttpHeaders({
          Authorization: `Bearer ${bearerToken}`,
        });
      }
  }