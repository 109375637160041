<ul
  class="kz-header-nav"
  (mouseleave)="onMouseLeave()"
  (appAccessControl)="isAllowed=$event">
  <li *appNgIfIsCommunity="{forceServerSide: true}" (mouseenter)="opena=true;" (mouseleave)="opena=false;">
    <a [routerLink]="['/kazacafe']| kzLocalize" appGtmTrack="menu_community_discover_0" style="cursor: pointer;" class="kz-header-nav-link" (mouseenter)="underlineLink($event)" (mouseleave)="removeUnderline()" data-underline-color="#88b04b" i18n="@@features.header.nav.community">Community</a>
    <div class="kz-header-submenu kz-header-submenu--community" (mouseenter)="underlineLink($event)" (mouseleave)="removeUnderline()" >
      <div class="kz-header-submenu-content" [@layerAnimation]="(opena)? 'open' : 'closed'">
        <div class="kz-header-submenu-grid">
          <div class="kz-header-submenu-grid-item kz-header-submenu-item--head">
            <kz-icons class="child-animate" name="community"></kz-icons>
            <p class="kz-header-submenu-head-title" i18n="@@features.header.nav.community.kazakafe">Kazacafé</p>
            <p class="kz-header-submenu-head-text" i18n="@@features.header.nav.community.lastAchievements">See members’ latest creations</p>
            <a [routerLink]="['/kazacafe']| kzLocalize" kzButton appGtmTrack="menu_community_discover_cta" buttonStyle="flat" buttonContext="community"><span i18n="@@features.header.nav.community.participateLink">Discover</span></a>
          </div>
          <div class="kz-header-submenu-grid-item">
            <ul class="kz-header-list">
              <li><a *ngIf="isAllowed" [routerLink]="['/kazacafe', 'follow', 'feed']| kzLocalize" appGtmTrack="menu_community_follow" i18n="@@features.header.nav.community.suivis">Following</a></li>
              <li *appNgIfIsCommunity="{forceServerSide: true}"><a [routerLink]="['/contest']| kzLocalize" appGtmTrack="menu_community_contest" i18n="@@features.header.nav.community.game">Contests</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
  <li (mouseenter)="openb=true;" (mouseleave)="openb=false;">
    <a [routerLink]="['/3d-home-design-software']| kzLocalize" appGtmTrack="menu_plan3D_0" style="cursor: pointer;" class="kz-header-nav-link" (mouseenter)="underlineLink($event)" (mouseleave)="removeUnderline()" data-underline-color="#3f9984" i18n="@@features.header.nav.tools">3D Plan</a>
    <div class="kz-header-submenu kz-header-submenu--tools" >
      <div class="kz-header-submenu-content" [@layerAnimation]="(openb)? 'open' : 'closed'">
        <div class="kz-header-submenu-grid">
          <div class="kz-header-submenu-grid-item kz-header-submenu-item--head">
            <kz-icons class="child-animate" name="plans"></kz-icons>
            <p class="kz-header-submenu-head-title" i18n="@@features.header.nav.tools.3dplan">3D Plan</p>
            <p class="kz-header-submenu-head-text" i18n="@@features.header.nav.tools.draw">Easily design 3D home plans</p>
            <a [routerLink]="['/3d-home-design-software']| kzLocalize" appGtmTrack="menu_plan3D_cta" kzButton buttonStyle="flat" buttonContext="tools"><span i18n="@@features.header.nav.tools.create3dMap">Create a 3D plan</span></a>
            <a *ngIf="!!user" style="width: 100%; margin-top: 8px" [routerLink]="['/kaza', '3Dplan'] | kzLocalize" kzButton buttonStyle="flat" buttonContext="tools"><span i18n="@@features.header.user.myPlans">My plans</span></a>
          </div>
          <div class="kz-header-submenu-grid-item">
            <ul class="kz-header-list">
              <li><a [routerLink]="['/3d-home-design-software','kitchen']| kzLocalize" appGtmTrack="menu_plan3D_plan3DKitchen" i18n="@@features.header.nav.tools.kitchen3dplan">Kitchen 3D plan</a></li>
              <li><a [routerLink]="['/3d-home-design-software','bathroom']| kzLocalize" appGtmTrack="menu_plan3D_plan3DBathroom" i18n="@@features.header.nav.tools.bathroom3dplan">Bathroom 3D plan</a></li>
              <li><a [routerLink]="['/3d-home-design-software','3d-landscaping-plan']| kzLocalize" appGtmTrack="menu_plan3D_3DdesignService" i18n="@@features.header.nav.tools.3dLandscapingPlan">3D landscaping plan</a></li>
              <li><a [routerLink]="['/3d-home-design-software','electrical-plan']| kzLocalize" appGtmTrack="menu_plan3D_plan3DElec" i18n="@@features.header.nav.tools.electricalschema">Electrical plan</a></li>
            </ul>
          </div>
          <div class="kz-header-submenu-grid-item">
            <ul class="kz-header-list"><!--<li><a [routerLink]="['/kazaplan/liste']| kzLocalize" i18n="@@features.header.nav.tools.gallery">Galerie de plans 3D</a></li>-->
              <li><a [routerLink]="['/3d-home-design-software','pricing']| kzLocalize" appGtmTrack="menu_plan3D_pricing" i18n="@@features.header.nav.tools.pricing">Pricing</a></li>
              <li *ngIf="user != null && user.activeKaza != null"><a [routerLink]="(user.activeKaza['@id'] | kazaLink: '3Dplan')| kzLocalize" appGtmTrack="menu_plan3D_myPlans" [queryParams]="{noredirect: true}" fragment="kaza_menu" i18n="@@features.header.nav.tools.myPlans">My 3D plans</a></li>
<!--              <li><a [routerLink]="['/3d-home-design-software','design-by-a-professional']| kzLocalize" appGtmTrack="menu_plan3D_3DdesignService" i18n="@@features.header.nav.tools.3ddesignservice">3D design service</a></li>-->
              <li><a href="https://kazaplan.zendesk.com/hc/fr" target="_blank" rel="noreferrer" appGtmTrack="menu_plan3D_helpCenter" i18n="@@features.header.nav.tools.helpCenter">3D plan help center</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
  <li *appNgIfIsCommunity="{forceServerSide: true}" (mouseenter)="openc=true;" (mouseleave)="openc=false;" >
    <span class="kz-header-nav-link" (mouseenter)="underlineLink($event)" (mouseleave)="removeUnderline()" data-underline-color="#1b6d9b" i18n="@@features.header.nav.tools.inspiration.title" >Inspiration</span>
    <div class="kz-header-submenu kz-header-submenu--inspiration" >
      <div class="kz-header-submenu-content" [@layerAnimation]="(openc)? 'open' : 'closed'">
        <div class="kz-header-submenu-grid">
          <div class="kz-header-submenu-grid-item kz-header-submenu-item--head">
            <kz-icons class="child-animate" name="idea"></kz-icons>
            <p class="kz-header-submenu-head-title" i18n="@@features.header.nav.tools.inspiration.title" >Inspiration</p>
            <p class="kz-header-submenu-head-text" i18n="@@features.header.nav.tools.inspiration.description">Find decorative ideas and layout tips</p>
          </div>
          <div class="kz-header-submenu-grid-item">
            <ul class="kz-header-list">
              <ng-container  (mouseleave)="openInspiSubNav = 0">
                <li>
                  <a [routerLink]="['/inspiration', 'bathroom']| kzLocalize"
                     [routerLinkActive]="['active']"
                     (mouseenter)="openInspiSubNav = 1"
                     appGtmTrack="menu_inspi_bathroom_1"
                     class="kz-header-list-special" data-sublist="bathroom"
                     i18n="@@features.header.nav.tools.inspiration.bathroom">Bathroom</a>
                  <div class="kz-header-list-sep"></div>
                  <ul class="kz-header-sublist" [ngClass]="{'active': inspirationBathroom && (openInspiSubNav == 1)}">
                    <li [@entranceAnimation]="{ value : (inspirationBathroom && (openInspiSubNav == 1))? 'active' : null, params : {time : '300ms', delay : '0ms' }}">
                      <a [href]="blogUrl + bathroomSlug"
                         appGtmTrack="menu_inspi_bathroom_posts"
                         i18n="@@features.header.nav.tools.inspiration.articles">Posts</a>
                    </li>
                    <li [@entranceAnimation]="{ value : (inspirationBathroom && (openInspiSubNav == 1))? 'active' : null, params : {time : '300ms', delay : '50ms' }}">
                      <a [routerLink]="['/inspiration', 'bathroom', 'photos']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.pictures"
                         appGtmTrack="menu_inspi_bathroom_photos"
                         *ngIf="inspirationBathroom.isMedia">Photos</a></li>
                    <li [@entranceAnimation]="{ value : (inspirationBathroom && (openInspiSubNav == 1))? 'active' : null, params : {time : '300ms', delay : '100ms' }}">
                      <a [routerLink]="['/inspiration', 'bathroom', '3d-plan']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.plans"
                         appGtmTrack="menu_inspi_bathroom_plans3D"
                         *ngIf="inspirationBathroom.isPlan">3D Plans</a></li>
                    <li [@entranceAnimation]="{ value : (inspirationBathroom && (openInspiSubNav == 1))? 'active' : null, params : {time : '300ms', delay : '150ms' }}">
                      <a [routerLink]="['/inspiration', 'bathroom', 'ideas']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.ideas"
                         appGtmTrack="menu_inspi_bathroom_ideas"
                         *ngIf="inspirationBathroom.isHD">HD Ideas</a></li>
                  </ul>
                </li>
                <li>
                  <a [routerLink]="['/inspiration', 'kitchen']| kzLocalize"
                     [routerLinkActive]="['active']"
                     (mouseenter)="openInspiSubNav = 2"
                     appGtmTrack="menu_inspi_kitchen_1"
                     class="kz-header-list-special" data-sublist="kitchen"
                     i18n="@@features.header.nav.tools.inspiration.kitchen">Kitchen</a>
                  <ul class="kz-header-sublist" [ngClass]="{'active': inspirationKitchen && (openInspiSubNav == 2)}">
                    <li [@entranceAnimation]="{ value : (inspirationBathroom && (openInspiSubNav == 2))? 'active' : null, params : {time : '300ms', delay : '0ms' }}">
                      <a [href]="blogUrl + kitchenSlug"
                         appGtmTrack="menu_inspi_kitchen_posts"
                         i18n="@@features.header.nav.tools.inspiration.articles">Posts</a>
                    </li>
                    <li [@entranceAnimation]="{ value : (inspirationBathroom && (openInspiSubNav == 2))? 'active' : null, params : {time : '300ms', delay : '50ms' }}">
                      <a [routerLink]="['/inspiration', 'kitchen', 'photos']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.pictures"
                         appGtmTrack="menu_inspi_kitchen_photos"
                         *ngIf="inspirationKitchen.isMedia">Photos</a></li>
                    <li [@entranceAnimation]="{ value : (inspirationBathroom && (openInspiSubNav == 2))? 'active' : null, params : {time : '300ms', delay : '100ms' }}">
                      <a [routerLink]="['/inspiration', 'kitchen', '3d-plan']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.plans"
                         appGtmTrack="menu_inspi_kitchen_plans3D"
                         *ngIf="inspirationKitchen.isPlan">3D Plans</a></li>
                    <li [@entranceAnimation]="{ value : (inspirationBathroom && (openInspiSubNav == 2))? 'active' : null, params : {time : '300ms', delay : '150ms' }}">
                      <a [routerLink]="['/inspiration', 'kitchen', 'ideas']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.ideas"
                         appGtmTrack="menu_inspi_kitchen_ideas"
                         *ngIf="inspirationKitchen.isHD">HD Ideas</a></li>
                 
                  </ul>
                </li>
                <li>
                  <a [routerLink]="['/inspiration', 'dressing']| kzLocalize"
                     [routerLinkActive]="['active']" class="kz-header-list-special"
                     (mouseenter)="openInspiSubNav = 3"
                     appGtmTrack="menu_inspi_wardrobe_1"
                     data-sublist="dressing" i18n="@@features.header.nav.tools.inspiration.dressing">Wardrobe and storage</a>
                  <ul class="kz-header-sublist" [ngClass]="{'active': inspirationDressing && (openInspiSubNav == 3)}">
                    <li [@entranceAnimation]="{ value : (inspirationDressing && (openInspiSubNav == 3))? 'active' : null, params : {time : '300ms', delay : '0ms' }}">
                      <a [href]="blogUrl + dressingSlug"
                         appGtmTrack="menu_inspi_wardrobe_posts"
                         i18n="@@features.header.nav.tools.inspiration.articles">Posts</a>
                    </li>
                    <li [@entranceAnimation]="{ value : (inspirationDressing && (openInspiSubNav == 3))? 'active' : null, params : {time : '300ms', delay : '50ms' }}">
                      <a [routerLink]="['/inspiration', 'dressing', 'photos']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.pictures"
                         appGtmTrack="menu_inspi_wardrobe_photos"
                         *ngIf="inspirationDressing.isMedia">Photos</a></li>
                    <li [@entranceAnimation]="{ value : (inspirationDressing && (openInspiSubNav == 3))? 'active' : null, params : {time : '300ms', delay : '100ms' }}">
                      <a [routerLink]="['/inspiration', 'dressing', '3d-plan']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.plans"
                         appGtmTrack="menu_inspi_wardrobe_plans3D"
                         *ngIf="inspirationDressing.isPlan">3D Plans</a></li>

                  </ul>
                </li>
                <li>
                  <a [routerLink]="['/inspiration', 'outdoor-design']| kzLocalize"
                     [routerLinkActive]="['active']" class="kz-header-list-special"
                     (mouseenter)="openInspiSubNav = 4"
                     appGtmTrack="menu_inspi_outdoor_design_1"
                     data-sublist="outdoor-design" i18n="@@features.header.nav.tools.inspiration.outdoor-design">Outdoor design</a>
                  <ul class="kz-header-sublist" [ngClass]="{'active': inspirationOutdoorDesign && (openInspiSubNav == 4)}">
                    <li [@entranceAnimation]="{ value : (inspirationOutdoorDesign && (openInspiSubNav == 4))? 'active' : null, params : {time : '300ms', delay : '0ms' }}">
                      <a [href]="blogUrl + outdoorDesignSlug"
                         appGtmTrack="menu_inspi_outdoor_design_posts"
                         i18n="@@features.header.nav.tools.inspiration.articles">Posts</a>
                    </li>
                    <li [@entranceAnimation]="{ value : (inspirationOutdoorDesign && (openInspiSubNav == 4))? 'active' : null, params : {time : '300ms', delay : '50ms' }}">
                      <a [routerLink]="['/inspiration', 'outdoor-design', 'photos', 'terrace']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.pictures.terrace"
                         appGtmTrack="menu_inspi_outdoor_design_photos_terrace"
                         *ngIf="inspirationOutdoorDesignTerrace.isMedia">Pictures of terraces</a></li>
                    <li [@entranceAnimation]="{ value : (inspirationOutdoorDesign && (openInspiSubNav == 4))? 'active' : null, params : {time : '300ms', delay : '100ms' }}">
                      <a [routerLink]="['/inspiration', 'outdoor-design', 'photos', 'garden']| kzLocalize"
                         i18n="@@features.header.nav.tools.inspiration.pictures.garden"
                         appGtmTrack="menu_inspi_outdoor_design_photos_garden"
                         *ngIf="inspirationOutdoorDesignGarden.isMedia">Pictures of gardens </a></li>
                    <li [@entranceAnimation]="{ value : (inspirationOutdoorDesign && (openInspiSubNav == 4))? 'active' : null, params : {time : '300ms', delay : '100ms' }}">
                      <a [routerLink]="['/inspiration', 'outdoor-design', '3d-plan']| kzLocalize"
                             i18n="@@features.header.nav.tools.inspiration.plans"
                             appGtmTrack="menu_inspi_outdoor_design_plans3D"
                             *ngIf="inspirationOutdoorDesign.isPlan">3D Plans </a></li>

                  </ul>
                </li>
              </ng-container>
              <li class="kz-header-list-mag">
                <a [href]="blogUrl" class="kz-header-list--le-mag"
                   appGtmTrack="menu_inspi_leMag">
                  <img loading="lazy" src="/assets/images/navigation/kozikaza-le-mag-gris.svg" width="80" height="34" alt=""><ng-container i18n="@@features.header.nav.tools.inspiration.mag">The Mag</ng-container>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
  <li *appNgIfIsCommunity="{forceServerSide: true}" (mouseenter)="opend=true;" (mouseleave)="opend=false;">
    <a [routerLink]="['/pro', 'interior-decorator']| kzLocalize" appGtmTrack="menu_pro_0" class="kz-header-nav-link" (mouseenter)="underlineLink($event)" (mouseleave)="removeUnderline()" data-underline-color="#004db3" i18n="@@features.header.nav.coaching.title">Decoration services</a>
    <div class="kz-header-submenu kz-header-submenu--coaching">
      <div class="kz-header-submenu-content"  [@layerAnimation]="(opend)? 'open' : 'closed'">
        <div class="kz-header-submenu-grid">
          <div class="kz-header-submenu-grid-item kz-header-submenu-item--head">
            <kz-icons class="child-animate" name="coaching"></kz-icons>
            <p class="kz-header-submenu-head-title" i18n="@@features.header.nav.coaching.sub-title">Decoration services</p>
            <p class="kz-header-submenu-head-text" i18n="@@features.header.nav.coaching.intro">180+ interior designers and architects in France</p>
            <a kzButton buttonStyle="flat" appGtmTrack="menu_pro_cta" buttonContext="decorator" [routerLink]="['pro']| kzLocalize" ><span i18n="@@features.header.nav.coaching.cta">I'm going</span></a>
          </div>
          <div class="kz-header-submenu-grid-item">
            <ul class="kz-header-list "><!--kz-header-list-mobile-->
              <li><a [routerLink]="['pro']| kzLocalize" appGtmTrack="menu_pro_allPros" i18n="@@features.header.nav.allPros.cta">All decoration professionals</a></li>
              <li><a [routerLink]="['pro', 'interior-decorator']| kzLocalize" appGtmTrack="menu_pro_interiorDecorators" i18n="@@features.header.nav.coachingList.cta">Interior decorators</a></li>
              <li><a [routerLink]="['pro', 'interior-architect']| kzLocalize" appGtmTrack="menu_pro_interiorArchitect" i18n="@@features.header.nav.coachingArchitect.cta">Interior architects</a></li>
              <li><a [routerLink]="['pro', 'deco-offers']| kzLocalize" appGtmTrack="menu_pro_decoOffers" i18n="@@features.header.nav.coachingServicesList.cta">All services</a></li>
            </ul>
          </div>
          <div class="kz-header-submenu-grid-item">
            <ul class="kz-header-list ">
              <ng-container *ngIf="(userStoreService.userStore | async) as u">
                <li *ngIf="!!u.professionalId"><a
                        [routerLink]="(u['@id'] | profileLink: 'coaching')| kzLocalize"
                        appGtmTrack="menu_pro_myCoachings"
                        i18n="@@features.header.nav.coachingProjects.cta">My decoration coaching</a></li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </li>
  <ng-container *ngIf="locale === 'fr'">
    <li *appNgIfIsCommunity="{forceServerSide: true}" (mouseenter)="opene=true;" (mouseleave)="opene=false;">
      <a [routerLink]="['/home-garden-deals']| kzLocalize" appGtmTrack="menu_good_deals_0" class="kz-header-nav-link kz-header-nav-link--goodDeal" (mouseenter)="underlineLink($event)" (mouseleave)="removeUnderline()" data-underline-color="#F02848" i18n="@@features.header.nav.goodDeals.title">Good deals</a>
      <div class="kz-header-submenu kz-header-submenu--goodDeals">
        <div class="kz-header-submenu--goodDeals--content"  [@layerAnimationD]="(opene)? 'open' : 'closed'">
          <a [routerLink]="['/home-garden-deals']| kzLocalize" class="card card--goodDeal"  appGtmTrack="menu_good_deals_cta">
            <div class="card__subTitle">
            </div>
            <div class="card__title">
            </div>
          </a>
          <app-good-deals-menu *ngFor="let dealArea of dealsOffers; trackBy: trackById;" [dealArea]="dealArea"></app-good-deals-menu>
        </div>
      </div>
    </li>
  </ng-container>
</ul>
<span class="kz-header-nav--underline" [ngClass]="{'kz-header-nav-transition': needTransition}"></span>
